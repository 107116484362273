<template>
  <div style="height=90%">
    <div class="msg">
      <table width="100%" class="table">
        <caption>
          <h2>短信记录(点击短信即可复制)</h2>
        </caption>
        <thead>
          <tr>
            <th>时间</th>
            <th>验证码</th>
          </tr>
        </thead>
        <tr
          v-for="item in list"
          :key="item.id"
          v-clipboard:copy="item.modle"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          <td>
            {{ item.time }}
          </td>
          <td>【{{ item.phone }}】{{ item.modle }}</td>
        </tr>
      </table>
    </div>
    <!--  翻页  -->
    <div class="pag">
      <van-pagination
        v-model="currentPage"
        :page-count="page_count"
        @change="click"
        mode="simple"
      />
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  name: "info",
  data() {
    return {
      list: [],
      page_count: 0,
      currentPage: 0,
    };
  },
  methods: {
    async click() {
      this.list = [];
      let ret = await this.$api.fn_get_expenditure((this.currentPage - 1) * 40);
      ret = ret.data;
      ret = ret.data;
      ret.forEach((element) => {
        let time = "";
        const data = new Date(parseInt(element.time) * 1000 - 3600000 * 8);
        time = data.toLocaleString();
        this.list.push({
          phone: element.phone,
          modle: element.modle,
          time: time,
        });
      });
    },
    //  复制的方法
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
  async mounted() {
    //  获取用户短信记录
    let ret = await this.$api.fn_get_expenditure(0);
    this.currentPage = 1;
    ret = ret.data;
    if(typeof(ret)=="string")
    {
      ret=ret.replace("\\/g", "");
      ret=(eval('(' + ret + ')'))
    }
    this.page_count = Math.ceil(ret.count / 40);
    ret = ret.data;
    ret.forEach((element) => {
      let time = "";
      const data = new Date(parseInt(element.time) * 1000 - 3600000 * 8);
      time = data.toLocaleString();
      this.list.push({
        phone: element.phone,
        modle: element.modle,
        time: time,
      });
    });
  },
  created() {
    Dialog.alert({
      message: "短信记录只保留3天",
    }).then(() => {
      // on close
    });
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 30px;
}
table thead th {
  background-color: #cce8eb;
  width: 100px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #cbf3f3;
}
.msg {
  padding-bottom: 90PX;
}
.pag {
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 99;
}
</style>
